app.controller('missingConfirmationsExceptionController', [
    '$scope', 'apiFactory', '$location', 'Upload', 'NgTableParams',
    function ($scope, apiFactory, $location, Upload, NgTableParams) {
        $scope.exception = {};
        $scope.block = {};
        $scope.blocks = [];

        getMissingConfirmationRequests();

        $scope.save_missingconf = function () {
            if ($scope.exception_file) {
                addExceptionWithFile();
            }
            else {
                addExceptionWithoutFile();
            }
        };

        function addExceptionWithoutFile() {
            console.log("getting here w/out file");
            apiFactory.addMissingConfirmationsExceptions($scope.exception, function () {
                alert('Exception added.');
                $scope.exception = {};
            });
        }

        function addExceptionWithFile() {
            console.log("getting here w file");
            $scope.exception.file = $scope.exception_file;

            Upload.upload({
                url: 'api/add_missing_confirmations_exceptions',
                data: $scope.exception
            }).then(function () {
                alert('Exception added and document uploaded successfully.')
            });
        }

        function getMissingConfirmationRequests()
        {
            $scope.is_getting = true;

            apiFactory.getMissingConfirmationsExceptions(function (data)
            {
                $scope.is_getting = false;

                $scope.confTable = new NgTableParams({}, {
                    dataset: data
                });

                $scope.all_data = data;

                // foreach (exception in data)
                // {
                //     exception.uploaded_confirmation = new FileController();
                // }

            });
        }

    }]);

