app.controller('territoryCreditExceptionController', [
    '$scope', 'apiFactory', 'NgTableParams',
    function ($scope, apiFactory, NgTableParams)
    {
        $scope.orders = [];
        $scope.exceptions = [];
        $scope.end_date = moment(new Date(Date.now())).add(1, 'month').toDate();
        $scope.start_date = moment(new Date(Date.now())).subtract(1, 'month').toDate();

        $scope.$watch('start_date', searchOrders);
        $scope.$watch('end_date', searchOrders);
        $scope.$watch('start_date', getExceptions);
        $scope.$watch('end_date', getExceptions);

        $scope.is_getting = 0;

        searchOrders();
        getExceptions();

        $scope.addException = function (order)
        {
            $scope.new_exception = {
                territory_subtract: order.ORDERSALESPERSON,
                order_date: order.ORDDATE,
                date_of_use: order.DATEOFUSE,
                order_number: order.ORDNO,
                amount_difference: Math.round(order.ORDERTOTAL / 2)
            };

            $scope.is_adding = true;

        };

        $scope.saveException = function ()
        {
            apiFactory.saveTteException($scope.new_exception, function (r)
            {
                console.log(r);
                $scope.is_adding = false;
                $scope.new_exception = {};
                getExceptions();
            })
        };

        $scope.removeException = function (id)
        {
            apiFactory.removeTteException(id, function (r)
            {
                getExceptions();
            })
        };

        function searchOrders()
        {
            $scope.is_getting++;
            apiFactory
                .getAllOrdersTimePeriod(
                    moment($scope.start_date).format('YYYY-MM-DD'),
                    moment($scope.end_date).format('YYYY-MM-DD'),
                    function (data)
                    {
                        $scope.orders_table = new NgTableParams({}, {
                            dataset: data
                        });
                        $scope.is_getting--;
                    });
        }

        function getExceptions()
        {
            $scope.is_getting++;
            apiFactory.getAllOrderExceptionsTimePeriod(
                moment($scope.start_date).format('YYYY-MM-DD'),
                moment($scope.end_date).format('YYYY-MM-DD'),
                function (data)
                {
                    $scope.exceptions_table = new NgTableParams({}, {
                        dataset: data
                    });
                    $scope.is_getting--;
                });
        }
    }
]);
