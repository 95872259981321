app.controller('customerConfirmationController', [
    '$scope', 'apiFactory', 'NgTableParams', '$filter', 'Upload', '$timeout', '$location', '$routeParams', "$uibModal",
    function ($scope, apiFactory, NgTableParams, $filter, Upload, $timeout, $location, $routeParams, $uibModal) {
        $scope.request = {};
        $scope.isSending = false;
        $scope.isLoading = false;
        $scope.includeOld = false;
        $scope.getAll = false;
        $scope.duplicateRequests = [];

        $scope.table_params = new NgTableParams({count: 10});

        $scope.$watch('order_file', function () {
            if (!$scope.order_file) {
                return;
            }
            var name    = $scope.order_file.name;
            var matches = name.match(/#(\d+) - (\d+)? \((.+)\)/);

            $scope.request.order_number  = matches[1];
            $scope.request.revision      = parseInt(matches[2]);
            $scope.request.customer_name = matches[3].split(" ").map(function (a) {
                return a.charAt(0) + a.slice(1).toLowerCase();
            }).join(" ");
        });

        $scope.saveRequest = function () {
            checkForExisting();
        };

        function checkForExisting() {
            var order_number = $scope.request.order_number;

            apiFactory.getRequeststoVoid(order_number, function (data) {
                if (data.length) {
                    promptForConfirmation(data);
                } else {
                    saveRequest();
                }
            });
        }

        function promptForConfirmation(data) {
            $uibModal.open({
                templateUrl: "/views/duplicate_request_void_prompt",
                controller: [
                    "$scope", "$uibModalInstance",
                    function ($innerScope, $uibModalInstance) {
                        $innerScope.data = data;

                        $innerScope.done = function () {
                            $uibModalInstance.close(true);
                        };

                        $innerScope.cancel = function () {
                            $uibModalInstance.close(false);
                        };
                    }
                ]
            }).result.then(function (doRequest) {
                if (doRequest) {
                    saveRequest();
                }
            });
        }

        function saveRequest() {
            if (
                !$scope.request.order_number ||
                !$scope.request.revision ||
                !$scope.request.customer_name ||
                !$scope.request.customer_email
            ) {
                alert("You are missing a required field.");
                return;
            }

            $scope.isSending = true;

            Upload.upload({
                url: '/api/add-confirmation-request',
                data: {
                    confirmation_request: $scope.request,
                    files: $scope.files,
                    order_file: $scope.order_file
                }
            }).then(function (response) {
                $timeout(function () {
                    $scope.isSending = false;

                    if (!response.data.valid) {
                        alert(response.data.error.string);
                    } else {
                        alert('An email was sent to the customer successfully.');
                        $location.path('/customer-confirmation/requests');
                    }
                });
            }, function (response) {
                $scope.isSending = false;
                alert("There was an error sending the request");
            }, function (evt, response) {
                $scope.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            });
        }

        $scope.confirmRequest = function () {
            apiFactory.confirmRequest({confirmation_request: $scope.request}, function (data) {
            });
        };

        $scope.getConfirmationRequest = function () {
            apiFactory.getConfirmationRequest($routeParams.confirmation_request, function (data) {
                $scope.request                   = data;
                $scope.request.event_date        = new Date(data.event_date);
                $scope.request.finalization_date = new Date(data.finalization_date);
            });
        };

        $scope.$watch('includeOld', function() {
            if ($scope.getAll)
                $scope.getAllRequests();
        });

        $scope.getAllRequests = function () {
            $scope.isLoading = true;
            $scope.getAll = true;
            apiFactory.getAllConfirmationRequests($scope.includeOld, function (data) {
                data.forEach(function (d) {
                    d.status = d.voided_at ? "Voided" : d.confirmed_by ? "Confirmed" : "Open";
                });

                $scope.table_params.settings({
                    dataset: data
                });

                $scope.isLoading = false;
            });
        };

        $scope.getMissingRequests = function () {
            $scope.isLoading = true;
            apiFactory.getMissingConfirmationRequests(function (data) {
                $scope.table_params.settings({
                    dataset: data
                });
                $scope.isLoading = false;
            });
        };

        $scope.remove_request = function (request_id) {
            $uibModal.open({
                template: "<div class='modal-body'>" +
                "<h2>Please state why you are voiding this confirmation.</h2>" +
                "<small>(This will be sent to the customer.)</small>" +
                "<input class='form-control' ng-model='reason' /><br />" +
                "</div><div class='modal-footer'>" +
                "<button class='btn btn-success' ng-click='done()'>Void</button>" +
                "<button class='btn btn-warning' ng-click='cancel()'>Cancel</button>" +
                "</div>",
                controller: [
                    '$uibModalInstance', '$scope',
                    function ($uibModalInstance, $scope) {
                        $scope.reason = "";
                        $scope.done   = function () {
                            $uibModalInstance.close($scope.reason);
                        };
                        $scope.cancel = function () {
                            $uibModalInstance.dismiss('cancel');
                        };
                    }]
            }).result.then(function (r) {
                if (r) {
                    apiFactory.removeRequest(request_id, r, function (data) {
                        if (data.is_error) {
                            alert(data.error_string);
                            return;
                        }

                        if ($location.path().indexOf('missing')) {
                            $scope.getMissingRequests();
                        } else {
                            $scope.getAllRequests();
                        }
                    });
                } else {
                    alert("You must enter a reason. Please try again.");
                }
            });

        };

        $scope.resendRequest  = function (request_id) {
            var conf = confirm("Are you sure you want to resend this request?");

            if (!conf) return;

            apiFactory.resendConfirmationRequestEmail(request_id, function (data) {
                if (data.is_error) {
                    alert(data.error_string);
                    return;
                } else {
                    alert('Email was sent successfully');
                }

            });
        };
    }
]);


