app.controller('indexController', [
    '$scope', 'apiFactory', '$location',
    function ($scope, apiFactory, $location)
    {

        $scope.did_auth_check = false;
        $scope.not_auth = false;

        apiFactory.isAuthenticated(function(data)
        {
            $scope.did_auth_check = true;
            if (data.authenticated)
            {
                $location.path('/home')
            }
            else
            {
                $scope.not_auth = true;
            }
        });
    }
]);