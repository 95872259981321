app.controller('corporateCustomersController', [
    '$scope', 'apiFactory', 'NgTableParams', '$filter',
    function ($scope, apiFactory, NgTableParams, $filter)
    {
        getCustomers();

        var to = null;
        $scope.$watch('last_order_date', function ()
        {
            clearTimeout(to);
            to = setTimeout(getCustomers, 1000);
        });

        $scope.downloadCsv = function()
        {
            var stringIt = function (str)
            {
                if (!str) return '""';

                return '"' +
                str.replace(/^\s\s*/, '').replace(/\s*\s$/, '') // trim spaces
                    .replace(/"/g, '""') + // replace quotes with double quotes
                '"';
            };

            var sep = ","

            var dl = stringIt("Customer Name") + sep + stringIt("Last Order Date");

            $scope.all_customers.forEach(function (cust)
            {
                dl += "\n" +
                    stringIt(cust.CUSTNAME) + sep +
                    stringIt(cust.LASTORDERDATE ? $filter('mysqlDate')(cust.LASTORDERDATE) : 'Never');
            });


            var link = document.createElement('a');
            link.download = "CorporateCustomers.csv";
            link.href = 'data:text/csv;charset=UTF-8,' + encodeURIComponent(dl);
            link.click();
        };

        function getCustomers()
        {
            $scope.is_getting_corporate_customers_table = true;

            apiFactory.getCorporateCustomers($scope.last_order_date, function (customers)
            {
                $scope.is_getting_corporate_customers_table = false;

                $scope.corporate_customers_table = new NgTableParams({}, {
                    dataset: customers
                });

                $scope.all_customers = customers;
            });
        }
    }
]);