
function standardizeDate(date) {
    if (date == null) {
        return '0000-00-00';
    }

    var delim = "-";
    if (date.indexOf(delim) == -1) {
        delim = "/";
    }

    var parts = date.split(delim);

    if (parts[0].length == 4) {
        //implies date format: yyyy-mm-dd
        return parts[0] + "-" + parts[1] + "-" + parts[2];
    } else {
        //implies date format: mm-dd-yyyy or mm/dd/yyyy
        return parts[2] + "-" + parts[0] + "-" + parts[1];
    }
}

function standardizeDateTime(date) {

    if (date == null) return null;

    var parts = date.split(" ");

    if (parts.length == 1) {
        //Just a simple date
        return standardizeDate(parts[0]);
    } else {
        return standardizeDate(parts[0]) + " " + parts[1];
    }
}

app.filter('mysqlDatetime', function (){
    return function(input, output)
    {
        input = standardizeDateTime(input);

        output = output || 'MM/DD/YYYY h:mm a';
        var parts = input.split(" ");

        var date_part = parts[0];
        var time_part = parts[1];

        var date_parts = date_part.split("-");
        var time_parts = time_part.split(":");

        var year = date_parts[0];
        var month = date_parts[1] - 1;
        var day = date_parts[2];

        var hour = time_parts[0];
        var minute = time_parts[1];
        var second = time_parts[2];

        var time = moment([
            year,
            month,
            day,
            hour,
            minute,
            second
        ]).format(output);

        return time === "Invalid date" ? "" : time;

    }
});

app.filter('mysqlDate', function(){
    return function(input, output)
    {
        input = standardizeDate(input);
        input = input || '0000-00-00';

        output = output || 'MM/DD/YYYY';

        var date_parts = input.split("-");
        var year = date_parts[0];
        var month = date_parts[1] - 1;
        var day = date_parts[2];

        var time = moment([
            year,
            month,
            day
        ]).format(output);

        return time === "Invalid date" ? "" : time;
    }
})
