app.controller('duplicateOpportunitiesController', [
    '$scope', 'apiFactory', '$routeParams', '$location', 'NgTableParams', '$timeout',
    function ($scope, apiFactory, $routeParams, $location, NgTableParams, $timeout)
    {
        $scope.is_getting_opportunities = true;
        $scope.opportunity_table_params = new NgTableParams({count: 10});
        $scope.ajax_abort_opportunties = null;

        $scope.$on('$destroy', function()
        {
            $scope.ajax_abort_opportunties('Cancel');
        });

        $timeout(function()
        {
            $scope.ajax_abort_opportunties = apiFactory.getDuplicateOpportunities(function (data)
            {
                $scope.is_getting_opportunities = false;

                $scope.opportunity_table_params.settings({
                    dataset: data
                });
            });
        }, 10);
    }
]);