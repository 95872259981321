app.controller('arrivalsNotTrackingController', [
    '$scope', 'apiFactory', '$routeParams', '$location', 'NgTableParams', '$timeout',
    function ($scope, apiFactory, $routeParams, $location, NgTableParams, $timeout)
    {
        var fresh = $location.search().fresh || false;

        $scope.is_getting = true;

        $scope.table_orders = new NgTableParams({count: 10});
        $scope.table_sample = new NgTableParams({count: 10});
        $scope.table_longterm = new NgTableParams({count: 10});

        $scope.canceller = null;

        $scope.$on('$destroy', function()
        {
            $scope.canceller('Cancel');
        });

        $scope.trackme = function (row, type) {
            apiFactory.workedOnArrivals(row.TRACKINGNO, function () {
                console.log($scope);
                var i = $scope['table_' + type].data.indexOf(row);
                $scope['table_' + type].data.splice(i, 1);
            });
        };

        $scope.getData = function () {
            $scope.canceller = apiFactory.getArrivalsNotTracking(fresh, function (data)
            {
                $scope.is_getting = false;

                $scope.table_orders.settings({
                    dataset: data.orders
                });

                $scope.table_sample.settings({
                    dataset: data.sample
                });

                $scope.table_longterm.settings({
                    dataset: data.longterm
                });
            });
        };

        $timeout(function()
        {
            $scope.getData();
        }, 10);
    }
]);