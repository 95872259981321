app.controller('usersController', [
    '$scope', 'apiFactory',
    function ($scope, apiFactory)
    {
        $scope.is_admin = false;

        apiFactory.getUserInfo(function (data)
        {
            $scope.is_admin = data.is_administrator;
        });

        $scope.users = [];
        $scope.is_add_user = false;
        $scope.is_edit_user = false;

        $scope.set_blank_user = function()
        {
            $scope.user_model = {
                email: '',
                is_manager: '0',
                allowed_territories: {}
            };

            for (var i in $scope.available_territories)
            {
                $scope.user_model.allowed_territories[$scope.available_territories[i]] = false;
            }
        };

        $scope.set_blank_user();

        $scope.start_edit_user = function(user)
        {
            $scope.is_edit_user = true;
            $scope.user_model = user;
        };

        $scope.is_toggled = true;

        $scope.toggle_all = function()
        {
            if ($scope.is_toggled)
            {
               for (var i in $scope.user_model.allowed_territories)
               {
                   $scope.user_model.allowed_territories[i] = true;
               }

                $scope.is_toggled = false;

            }
            else
            {
                for (var i in $scope.user_model.allowed_territories)
                {
                    $scope.user_model.allowed_territories[i] = false;
                }

                $scope.is_toggled = true;
            }
        };

        $scope.add_user = function ()
        {
            apiFactory.addUser($scope.user_model, function(data)
            {
                if (data.is_error)
                {
                    alert(data.error_string);
                    return;
                }

                $scope.is_add_user = false;

                getAvailableUsers();
            });
        };

        $scope.edit_user = function ()
        {
            apiFactory.editUser($scope.user_model, function(data)
            {
                if (data.is_error)
                {
                    alert(data.error_string);
                    return;
                }

                $scope.is_edit_user = false;

                getAvailableUsers();
            })
        };

        $scope.remove_user = function (user_id)
        {
            apiFactory.removeUser(user_id, function (data)
            {
                if (data.is_error)
                {
                    alert(data.error_string);
                    return;
                }

                getAvailableUsers();
            });
        };

        getAvailableUsers();

        function getAvailableUsers()
        {
            apiFactory.getAvaliableUsers(function(data)
            {
                $scope.users.length = 0;

                for (i in data)
                {
                    var user = {};
                    user.id = data[i].id;
                    user.email = data[i].email;
                    user.is_manager = data[i].is_manager.toString();
                    user.is_administrator = data[i].is_administrator.toString();
                    user.allowed_territories = {};
                    user.default_territory = data[i].default_territory;
                    user.pt_initials = data[i].pt_initials;

                    for (var t in $scope.available_territories)
                    {
                        user.allowed_territories[$scope.available_territories[t]] = false;
                    }

                    for (var s in data[i].allowed_territories)
                    {
                        user.allowed_territories[data[i].allowed_territories[s]] = true;
                    }

                    $scope.users.push(user);
                }
            });
        }
    }
]);
