app.controller('testProjectionsExportController', [
    '$scope', 'apiFactory',
    function ($scope, apiFactory)
    {
        $scope.start = "";
        $scope.end = "";


        $scope.getProjections = function()
        {
            var cb = function (leads)
            {
                window.open('/api/get_projections/download');
            };

            apiFactory.getProjectionsExport(cb, $scope.start, $scope.end);

        }
    }
]);
