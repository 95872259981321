 app.controller('productionController', [
    '$scope', 'apiFactory', '$routeParams', '$location', '$filter', "$timeout", "NgTableParams",
    function ($scope, apiFactory, $routeParams, $location, $filter, $timeout, NgTableParams)
    {
        $scope.getting_data = true;

        $scope.territories = [];
        $scope.start_date = new Date();
        $scope.end_date = new Date();
        $scope.territory = $routeParams.territory;

        $scope.$watch('territory', function (o)
        {
            $location.path('/production/' + $scope.territory);
        });

        $scope.$watch('start_date', run);
        $scope.$watch('end_date', run);
        // $scope.$watch('territory', run);


        $timeout(function ()
        {
            getUserData();
            run();
        }, 10);

        function getUserData()
        {
            apiFactory.getUserInfo(function (data)
            {
                $scope.territories = data.allowed_territories;
            });
        }

        function run()
        {
            apiFactory.production($scope.start_date, $scope.end_date, $scope.territory, function (data) {
                data.forEach(function (row) {
                    row.FINAL = row.SHIPINFOOK === null || row.SHIPINFOOK === 'N';
                });
                $scope.table = new NgTableParams({sorting: {DATEOFUSE: 'desc'}}, {dataset: data});

            });
        }

    }
]);
