app.controller('missingConfirmationsController', [
    '$scope', 'apiFactory', '$location', 'NgTableParams', '$timeout', '$routeParams',
    function ($scope, apiFactory, $location, NgTableParams, $timeout, $routeParams) {
        $scope.is_getting = true;

        $scope.table_params = new NgTableParams({count: 10});

        $scope.ajax_abort = null;

        $scope.$on('$destroy', function () {
            $scope.ajax_abort('Cancel');
        });

        $scope.workon = function (row) {
            apiFactory.workedOnConfirmation(row.ORDNO, function () {
                row.DIDWO = true;
            });
        };

        $scope.onlyNotWorkedOn = false;

        $scope.$watch('onlyNotWorkedOn', function () {
            if ($scope.onlyNotWorkedOn) {
                $scope.table_params.settings({
                    dataset: $scope.data.filter(function (d) {
                        return !d.DIDWO;
                    })
                });
            } else {
                $scope.table_params.settings({
                    dataset: $scope.data
                });
            }
        });

        run();

        function run() {
            $timeout(function () {
                $scope.ajax_abort = apiFactory.getMissingConfirmations($routeParams.territory, function (data) {
                    $scope.is_getting = false;

                    $scope.data = data;

                    $scope.table_params.settings({
                        dataset: data
                    });
                });
            }, 10);
        }
    }
]);
