 app.controller('salesReportController', [
    '$scope', 'apiFactory', '$routeParams', '$location', '$filter', "$timeout", 'sales_reports_hire_dates', '$uibModal',
    function ($scope, apiFactory, $routeParams, $location, $filter, $timeout, sales_reports_hire_dates, $uibModal)
    {
        $scope.getting_sales_data = true;

        var n = new Date();

        $scope.territories = [];
        $scope.pyears = [n.getFullYear() + 1, n.getFullYear(), n.getFullYear() - 1, n.getFullYear() - 2, n.getFullYear() - 3, n.getFullYear() - 4, n.getFullYear() - 5];

        $scope.territory = $routeParams.territory;
        $scope.year = $routeParams.year;

        var fresh = $location.search().fresh || false;

        var orderBy = $filter('orderBy');


        //Display list of orders with credits for given territory
        $scope.create = function() {
            var modal = $uibModal.open({
                size: 'lg',
                templateUrl: '/views/sales_report_credits',
                controller: 'modalCreditsController',
                resolve: {
                    territory: function(){
                        return $scope.territory;
                    },
                    incoming_credit: function(){
                        return $scope.production_year_credits_in;
                    },
                    outgoing_credit: function(){
                      return $scope.production_year_credits_out;
                    },
                    credit_sum: function(){
                      return $scope.production_year_credits_sum;
                    }

                }
            })
        };



        $scope.$watch('year', function (o)
        {
            $location.path('/sales_report/' + $scope.territory + '/' + o);
        });

        $scope.$watch('territory', function (o)
        {
            $location.path('/sales_report/' + o + '/' + $scope.year);
        });

        $scope.pending = {
            order_breakdown: true,
            order_to_dates: true,
            order_year_to_year: true,
            production_breakdown: true,
            production_year_to_year: true,
            production_to_dates: true,
            order_goals: true,
            production_goals: true
        }

        $scope.cancellers = {
            order_breakdown: false,
            order_to_dates: false,
            order_year_to_year: false,
            production_breakdown: false,
            production_year_to_year: false,
            production_to_dates: false,
            order_goals: false,
            production_goals: false
        };

        $scope.extraClasses = function (year, month)
        {
            if (sales_reports_hire_dates[$scope.territory])
            {
                if (sales_reports_hire_dates[$scope.territory].year == year && sales_reports_hire_dates[$scope.territory].month == month)
                {
                    return 'starting_hire';
                }
            }
        };

        $scope.$on('$destroy', function ()
        {
            for (var i in $scope.cancellers)
            {
                $scope.cancellers[i]('Cancelled');
            }
        });

        $timeout(function ()
        {
            getUserData();
            run();
        }, 10);

        function getUserData()
        {
            apiFactory.getUserInfo(function (data)
            {
                $scope.territories = data.allowed_territories;
            });
        }

        function run()
        {
            $scope.pending = {
                order_breakdown: true,
                order_to_dates: true,
                order_year_to_year: true,
                production_breakdown: true,
                production_year_to_year: true,
                production_to_dates: true,
                order_goals: true,
                production_goals: true,
                production_year_credits_in: true,
                production_year_credits_out: true,
                production_year_credits_sum: true

            };

            $scope.cancellers.order_breakdown = apiFactory.getSalesData($scope.territory, $scope.year, 'order_breakdown', fresh, function (data)
            {
                $scope.pending.order_breakdown = false;
                $scope.order_breakdown = data;
            });

            $scope.cancellers.order_to_dates = apiFactory.getSalesData($scope.territory, $scope.year, 'order_to_dates', fresh, function (data)
            {
                $scope.pending.order_to_dates = false;
                $scope.order_to_dates = data;
            });

            $scope.cancellers.order_year_to_year = apiFactory.getSalesData($scope.territory, $scope.year, 'order_year_to_year', fresh, function (data)
            {
                $scope.pending.order_year_to_year = false;
                $scope.order_year_to_year = data;
            });

            $scope.cancellers.production_breakdown = apiFactory.getSalesData($scope.territory, $scope.year, 'production_breakdown', fresh, function (data)
            {
                $scope.pending.production_breakdown = false;
                $scope.production_breakdown = data;
            });

            $scope.cancellers.production_year_to_year = apiFactory.getSalesData($scope.territory, $scope.year, 'production_year_to_year', fresh, function (data)
            {
                $scope.pending.production_year_to_year = false;
                $scope.production_year_to_year = data;
            });

            $scope.cancellers.production_to_dates = apiFactory.getSalesData($scope.territory, $scope.year, 'production_to_dates', fresh, function (data)
            {
                $scope.pending.production_to_dates = false;
                $scope.production_to_dates = data;
            });

            $scope.cancellers.order_goals = apiFactory.getSalesData($scope.territory, $scope.year, 'order_goals', fresh, function (data)
            {
                $scope.pending.order_goals = false;
                $scope.order_goals = data;
            });

            $scope.cancellers.production_goals = apiFactory.getSalesData($scope.territory, $scope.year, 'production_goals', fresh, function (data)
            {
                $scope.pending.production_goals = false;
                $scope.production_goals = data;
            });
            $scope.cancellers.production_year_credits_in = apiFactory.getSalesData($scope.territory, $scope.year, 'production_year_credits_in', fresh, function (data)
            {
                $scope.pending.production_year_credits_in = false;
                $scope.production_year_credits_in = data;
            });
            $scope.cancellers.production_year_credits_out = apiFactory.getSalesData($scope.territory, $scope.year, 'production_year_credits_out', fresh, function (data)
            {
                $scope.pending.production_year_credits_out = false;
                $scope.production_year_credits_out = data;
            });
            $scope.cancellers.production_year_credits_sum = apiFactory.getSalesData($scope.territory, $scope.year, 'production_year_credits_sum', fresh, function (data)
            {
                $scope.pending.production_year_credits_sum = false;
                $scope.production_year_credits_sum = data;
            });

        }


    }
]);

app.controller('modalCreditsController', ['$scope', '$http', 'territory', '$uibModal', '$uibModalInstance', 'incoming_credit', 'outgoing_credit', 'credit_sum',
function ($scope, $http, territory, $uibModal, $uibModalInstance, incoming_credit, outgoing_credit, credit_sum) {
    $scope.territory = territory;
    $scope.incoming_credit = incoming_credit;
    $scope.outgoing_credit = outgoing_credit;
    $scope.credit_sum = credit_sum;

    $scope.accounts_with_credit = {} ;

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    }

}]);
