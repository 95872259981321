app.controller('menuController', [
    '$scope', 'apiFactory',
    function ($scope, apiFactory)
    {
        $scope.is_authenticated = false;
        $scope.start_year = (new Date()).getFullYear();
        $scope.territories = [];
        $scope.is_manager = false;
        $scope.is_administrator = false;

        apiFactory.isAuthenticated(function(data)
        {
            if (data.authenticated)
            {
                $scope.is_authenticated = true;

                $scope.territories = {};

                apiFactory.getUserInfo(function (data)
                {
                    $scope.territories = data.allowed_territories;
                    $scope.is_manager = data.is_manager;
                    $scope.is_administrator = data.is_administrator;
                });
            }
        });


    }
]);
