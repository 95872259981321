app.controller('futureOrdersOver3000Controller', [
    '$scope', 'apiFactory', 'NgTableParams', '$filter',
    function ($scope, apiFactory, NgTableParams, $filter)
    {
        $scope.loading = false;

        getOrders();

        $scope.downloadCsv = function()
        {
            var stringIt = function (str)
            {
                if (!str) return '""';

                str = str.toString();

                return '"' +
                    str.replace(/^\s\s*/, '').replace(/\s*\s$/, '') // trim spaces
                        .replace(/"/g, '""') + // replace quotes with double quotes
                    '"';
            };

            var sep = ","

            var dl = stringIt("Order #") + sep +
                     stringIt("Customer Name") + sep +
                     stringIt("Event Date") + sep +
                     stringIt("Order Date") + sep +
                     stringIt("Order $");

            $scope.orders.forEach(function (row)
            {
                dl += "\n" +
                    stringIt(row.ORDNO) + sep +
                    stringIt(row.CUSTNAME) + sep +
                    stringIt(row.DATEOFUSE ? $filter('mysqlDate')(row.DATEOFUSE) : 'Unknown') + sep +
                    stringIt(row.ORDDATE ? $filter('mysqlDate')(row.ORDDATE) : 'Unknown') + sep +
                    stringIt(row.ORDERTOTAL ? $filter('currency')(row.ORDERTOTAL) : 'Unknown');
            });


            var link = document.createElement('a');
            link.download = "FutureOrdersOver3k.csv";
            link.href = 'data:text/csv;charset=UTF-8,' + encodeURIComponent(dl);
            link.click();
        };

        function getOrders()
        {
            $scope.loading = true;

            apiFactory.futureOrdersOver3000(function (data)
            {
                $scope.loading = false;
                $scope.table = new NgTableParams({sorting: {ORDDATE: 'desc'}}, {dataset: data});
                $scope.orders = data;
            })
        }

    }
]);