app.controller('homeController', [
    '$scope', 'apiFactory', '$timeout', 'NgTableParams',
    function ($scope, apiFactory, $timeout, NgTableParams)
    {
        var n = new Date();
        $scope.pyears = [n.getFullYear() + 1, n.getFullYear(), n.getFullYear() - 1, n.getFullYear() - 2, n.getFullYear() - 3, n.getFullYear() - 4, n.getFullYear() - 5];
        $scope.territories = {};
        $scope.territory = '';
        $scope.current_year = (new Date()).getFullYear();
        $scope.current_month = (new Date()).getMonth() + 1;
        $scope.current_month_name = moment().month($scope.current_month).format('MMM');
        $scope.current_quarter = 'Q' + (Math.ceil($scope.current_month / 3));
        $scope.months = {
            1: 'Jan',
            2: 'Feb',
            3: 'Mar',
            4: 'Apr',
            5: 'May',
            6: 'Jun',
            7: 'Jul',
            8: 'Aug',
            9: 'Sep',
            10: 'Oct',
            11: 'Nov',
            12: 'Dec'
        };
        $scope.productionLoading = true;
        $scope.ordersLoading = true;
        $scope.userSalesLoading = true;

        setupCharts();

        apiFactory.getUserInfo(function (data)
        {
            $scope.productionLoading = false;
            $scope.ordersLoading = false;
            $scope.userSalesLoading = false;
            $scope.territory = '' + data.default_territory;
            $scope.territories = data.allowed_territories;
            $scope.is_manager = data.is_manager;
            $scope.is_administrator = data.is_administrator;
            $scope.has_initials = data.pt_initials.length > 0;
            updateChartsTerritoryCharts();
            updateUserCharts();
        });

        $scope.$watch('territory', function (a, b)
        {
            console.log(a, b);
            updateChartsTerritoryCharts();
        }, true);

        $scope.$watch('current_year', function ()
        {
            updateChartsTerritoryCharts($scope, apiFactory, $timeout);
        }, true);

        $scope.$watch('current_month', function ()
        {
            $scope.current_month_name = moment().month($scope.current_month - 1).format('MMM');
            $scope.current_quarter = 'Q' + (Math.ceil($scope.current_month / 3));
            updateChartsTerritoryCharts();
        }, true);


        function updateUserCharts()
        {
            if (!$scope.has_initials) return;

            if (!$scope.userSalesLoading)
            {
                $scope.userSalesLoading = true;
                apiFactory.getUserSales($scope.current_year, function (data)
                {
                    $scope.userSalesLoading = false;
                    $scope.userSalesChartYesterday.series[0].data[0].y = parseFloat(data.cYearYesterday.sales);
                    $scope.userSalesChartLast7.series[0].data[0].y = parseFloat(data.cYearLast7.sales);
                    $scope.userSalesChartMTD.series[0].data[0].y = parseFloat(data.cYearMTD.sales);
                    $scope.userSalesChartLast30.series[0].data[0].y = parseFloat(data.cYearLast30.sales);
                    $scope.userSalesChartLast90.series[0].data[0].y = parseFloat(data.cYearLast90.sales);
                    $scope.userSalesChartLast180.series[0].data[0].y = parseFloat(data.cYearLast180.sales);
                    $scope.userSalesChartLast365.series[0].data[0].y = parseFloat(data.cYearLast365.sales);
                    $scope.userSalesChartYTD.series[0].data[0].y = parseFloat(data.cYearYTD.sales);

                    $scope.userSalesChartYesterday.series[0].data[1].y = parseFloat(data.pYearYesterday.sales);
                    $scope.userSalesChartLast7.series[0].data[1].y = parseFloat(data.pYearLast7.sales);
                    $scope.userSalesChartMTD.series[0].data[1].y = parseFloat(data.pYearMTD.sales);
                    $scope.userSalesChartLast30.series[0].data[1].y = parseFloat(data.pYearLast30.sales);
                    $scope.userSalesChartLast90.series[0].data[1].y = parseFloat(data.pYearLast90.sales);
                    $scope.userSalesChartLast180.series[0].data[1].y = parseFloat(data.pYearLast180.sales);
                    $scope.userSalesChartLast365.series[0].data[1].y = parseFloat(data.pYearLast365.sales);
                    $scope.userSalesChartYTD.series[0].data[1].y = parseFloat(data.pYearYTD.sales);
                    $timeout(function ()
                    {
                        $scope.$broadcast('highchartsng.reflow');
                    }, 10);
                });
            }
        }

        function updateChartsTerritoryCharts()
        {
            if (!$scope.territory) return;

            if (!$scope.productionLoading)
            {
                $scope.productionLoading = true;

                apiFactory.getSalesData($scope.territory, $scope.current_year, 'production_goals', false, function (data)
                {
                    $scope.productionLoading = false;
                    //Month
                    $scope.productionGoalsMonthChart.options.xAxis.categories = [
                        $scope.current_year - 1,
                        $scope.current_year + ' Goal',
                        $scope.current_year
                    ];
                    $scope.productionGoalsMonthChart.series[0].data = [
                        data[$scope.current_month].year1,
                        data[$scope.current_month].totalGoal,
                        data[$scope.current_month].year0
                    ];

                    $scope.productionGoalsMonthDiff = data[$scope.current_month].goalChange

                    //Quarter
                    $scope.productionGoalsQuarterChart.options.xAxis.categories = [
                        $scope.current_year - 1,
                        $scope.current_year + ' Goal',
                        $scope.current_year
                    ];
                    $scope.productionGoalsQuarterChart.series[0].data = [
                        data[$scope.current_quarter].year1,
                        data[$scope.current_quarter].totalGoal,
                        data[$scope.current_quarter].year0
                    ];

                    $scope.productionGoalsQuarterDiff = data[$scope.current_quarter].goalChange
                    $timeout(function ()
                    {
                        $scope.$broadcast('highchartsng.reflow');
                    }, 10);
                });
            }

            if (!$scope.ordersLoading)
            {
                $scope.ordersLoading = true;
                apiFactory.getSalesData($scope.territory, $scope.current_year, 'order_goals', false, function (data)
                {
                    $scope.ordersLoading = false;
                    //Month
                    $scope.orderGoalsMonthChart.options.xAxis.categories = [
                        $scope.current_year - 1,
                        $scope.current_year + ' Goal',
                        $scope.current_year
                    ];
                    $scope.orderGoalsMonthChart.series[0].data = [
                        data[$scope.current_month].year1,
                        data[$scope.current_month].totalGoal,
                        data[$scope.current_month].year0
                    ];

                    $scope.orderGoalsMonthDiff = data[$scope.current_month].goalChange

                    //Quarter
                    $scope.orderGoalsQuarterChart.options.xAxis.categories = [
                        $scope.current_year - 1,
                        $scope.current_year + ' Goal',
                        $scope.current_year
                    ];
                    $scope.orderGoalsQuarterChart.series[0].data = [
                        data[$scope.current_quarter].year1,
                        data[$scope.current_quarter].totalGoal,
                        data[$scope.current_quarter].year0
                    ];

                    $scope.orderGoalsQuarterDiff = data[$scope.current_quarter].goalChange
                    $timeout(function ()
                    {
                        $scope.$broadcast('highchartsng.reflow');
                    }, 10);
                });
            }
        }

        function setupCharts()
        {
            var goalsPlotOptions = {
                column: {
                }
            };

            $scope.productionGoalsMonthChart = {
                options: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'column'
                    },
                    xAxis: {
                        categories: [
                            $scope.current_year - 1,
                            $scope.current_year + ' Goal',
                            $scope.current_year
                        ]
                    },
                    yAxis: {
                        title: "Production"
                    },
                    plotOptions: goalsPlotOptions,
                    legend: false
                },
                title: {
                    text: false
                },
                size: {
                    height: 150
                },
                series: [{
                    name: 'Production',
                    data: []
                }]
            };
            $scope.productionGoalsMonthDiff = 0;

            $scope.productionGoalsQuarterChart = {
                options: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'column'
                    },
                    xAxis: {
                        categories: [
                            $scope.current_year - 1,
                            $scope.current_year + ' Goal',
                            $scope.current_year
                        ]
                    },
                    yAxis: {
                        title: "Production"
                    },
                    plotOptions: goalsPlotOptions,
                    legend: false
                },
                title: {
                    text: false
                },
                size: {
                    height: 150
                },
                series: [{
                    name: 'Production',
                    data: []
                }]
            };
            $scope.productionGoalsQuarterDiff = 0;

            $scope.orderGoalsMonthChart = {
                options: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'column'
                    },
                    xAxis: {
                        categories: [
                            $scope.current_year - 1,
                            $scope.current_year + ' Goal',
                            $scope.current_year
                        ]
                    },
                    yAxis: {
                        title: "Order"
                    },
                    plotOptions: goalsPlotOptions,
                    legend: false
                },
                title: {
                    text: false
                },
                size: {
                    height: 150
                },
                series: [{
                    name: 'Orders',
                    data: []
                }]
            };
            $scope.orderGoalsMonthDiff = 0;

            $scope.orderGoalsQuarterChart = {
                options: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'column'
                    },
                    xAxis: {
                        categories: [
                            $scope.current_year - 1,
                            $scope.current_year + ' Goal',
                            $scope.current_year
                        ]
                    },
                    yAxis: {
                        title: "Order"
                    },
                    plotOptions: goalsPlotOptions,
                    legend: false
                },
                title: {
                    text: false
                },
                size: {
                    height: 150
                },
                series: [{
                    name: 'Orders',
                    data: []
                }]
            };
            $scope.orderGoalsQuarterDiff = 0;

            var userSalesPlotOptions = {
                pie: {
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b><br />${point.y:.2f}',
                        distance: 5,
                        style: {
                            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                        }
                    }
                }
            };

            $scope.userSalesChartYesterday = {
                options: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'pie'
                    },
                    xAxis: {
                        categories: [
                            'Yesterday',
                        ]
                    },
                    plotOptions: userSalesPlotOptions,
                    legend: false
                },
                title: {
                    text: 'Yesterday',

                },
                size: {
                    height: 150
                },
                series: [{
                    name: 'Sales',
                    data: [{
                        name: $scope.current_year,
                        y: 0
                    }, {
                        name: $scope.current_year - 1,
                        y: 0
                    }]
                }]
            };

            $scope.userSalesChartLast7 = {
                options: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'pie'
                    },
                    xAxis: {
                        categories: [
                            'Last 7',
                        ]
                    },
                    plotOptions: userSalesPlotOptions,
                    legend: false
                },
                title: {
                    text: 'Last 7',

                },
                size: {
                    height: 150
                },
                series: [{
                    name: 'Sales',
                    data: [{
                        name: $scope.current_year,
                        y: 0
                    }, {
                        name: $scope.current_year - 1,
                        y: 0
                    }]
                }]
            };

            $scope.userSalesChartLast30 = {
                options: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'pie'
                    },
                    xAxis: {
                        categories: [
                            'Last 30',
                        ]
                    },
                    plotOptions: userSalesPlotOptions,
                    legend: false
                },
                title: {
                    text: 'Last 30',

                },
                size: {
                    height: 150
                },
                series: [{
                    name: 'Sales',
                    data: [{
                        name: $scope.current_year,
                        y: 0
                    }, {
                        name: $scope.current_year - 1,
                        y: 0
                    }]
                }]
            };

            $scope.userSalesChartLast90 = {
                options: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'pie'
                    },
                    xAxis: {
                        categories: [
                            'Last 90',
                        ]
                    },
                    plotOptions: userSalesPlotOptions,
                    legend: false
                },
                title: {
                    text: 'Last 90',

                },
                size: {
                    height: 150
                },
                series: [{
                    name: 'Sales',
                    data: [{
                        name: $scope.current_year,
                        y: 0
                    }, {
                        name: $scope.current_year - 1,
                        y: 0
                    }]
                }]
            };

            $scope.userSalesChartLast180 = {
                options: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'pie'
                    },
                    xAxis: {
                        categories: [
                            'Last 180',
                        ]
                    },
                    plotOptions: userSalesPlotOptions,
                    legend: false
                },
                title: {
                    text: 'Last 180',

                },
                size: {
                    height: 150
                },
                series: [{
                    name: 'Sales',
                    data: [{
                        name: $scope.current_year,
                        y: 0
                    }, {
                        name: $scope.current_year - 1,
                        y: 0
                    }]
                }]
            };

            $scope.userSalesChartLast365 = {
                options: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'pie'
                    },
                    xAxis: {
                        categories: [
                            'Last 360',
                        ]
                    },
                    plotOptions: userSalesPlotOptions,
                    legend: false
                },
                title: {
                    text: 'Last 360',

                },
                size: {
                    height: 150
                },
                series: [{
                    name: 'Sales',
                    data: [{
                        name: $scope.current_year,
                        y: 0
                    }, {
                        name: $scope.current_year - 1,
                        y: 0
                    }]
                }]
            };

            $scope.userSalesChartMTD = {
                options: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'pie'
                    },
                    xAxis: {
                        categories: [
                            'MTD',
                        ]
                    },
                    plotOptions: userSalesPlotOptions,
                    legend: false
                },
                title: {
                    text: 'MTD',

                },
                size: {
                    height: 150
                },
                series: [{
                    name: 'Sales',
                    data: [{
                        name: $scope.current_year,
                        y: 0
                    }, {
                        name: $scope.current_year - 1,
                        y: 0
                    }]
                }]
            };

            $scope.userSalesChartYTD = {
                options: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'pie'
                    },
                    xAxis: {
                        categories: [
                            'YTD',
                        ]
                    },
                    plotOptions: userSalesPlotOptions,
                    legend: false
                },
                title: {
                    text: 'YTD',

                },
                size: {
                    height: 150
                },
                series: [{
                    name: 'Sales',
                    data: [{
                        name: $scope.current_year,
                        y: 0
                    }, {
                        name: $scope.current_year - 1,
                        y: 0
                    }]
                }]
            };


        }
    }
]);

