app.controller('ordersWOOrderDate', [
    '$scope', 'apiFactory', '$routeParams', '$location', 'NgTableParams', '$timeout',
    function ($scope, apiFactory, $routeParams, $location, NgTableParams, $timeout)
    {
        getOrdersWODate();

        $scope.workon = function (row) {
            apiFactory.workedOnOrdersNoDate(row.ORDNO, function () {
                row.DIDWO = true;
            });
        };

        $scope.$watch('onlyNotWorkedOn', function () {
            if ($scope.onlyNotWorkedOn) {
                $scope.table_ship.settings({
                    dataset: $scope.shipData.filter(function (d) {
                        return !d.DIDWO;
                    })
                });
                $scope.table_local.settings({
                    dataset: $scope.localData.filter(function (d) {
                        return !d.DIDWO;
                    })
                });
            } else {
                $scope.table_ship.settings({
                    dataset: $scope.shipData
                });
                $scope.table_local.settings({
                    dataset: $scope.localData
                });
            }
        });

        function getOrdersWODate()
        {
            $scope.is_getting = true;

            apiFactory.getOrdersWODate(function (data)
            {
                $scope.is_getting = false;

                $scope.ordersTable = new NgTableParams({}, {
                    dataset: data
                });

                $scope.all_data = data;
            });
        }
    }
]);