app.controller('erroredController', [
    '$scope', 'apiFactory', '$routeParams', '$location', 'NgTableParams', '$timeout',
    function ($scope, apiFactory, $routeParams, $location, NgTableParams, $timeout)
    {

        $scope.is_getting_customers = true;
        $scope.customer_table_params = new NgTableParams({count: 10});
        $scope.ajax_abort_customers = null;

        $scope.is_getting_contacts = true;
        $scope.contact_table_params = new NgTableParams({count: 10});
        $scope.ajax_abort_contacts = null;

        $scope.is_getting_orders = true;
        $scope.order_table_params = new NgTableParams({count: 10});
        $scope.ajax_abort_orders = null;

        $scope.$on('$destroy', function()
        {
            $scope.ajax_abort_customers('Cancel');
            $scope.ajax_abort_contacts('Cancel');
            $scope.ajax_abort_orders('Cancel');
        });

        $timeout(function()
        {

            $scope.ajax_abort_customers = apiFactory.getErroredCustomers(function (data)
            {
                $scope.is_getting_customers = false;

                $scope.customer_table_params.settings({
                    dataset: data
                });
            });
        }, 10);

        //ALLIE TEST
        $scope.workedError = function (row) {
            apiFactory.workedOnErrors(row.error.custno, function () {
                row.DIDWO = true;
            });
        };
        //ALLIE TEST
        $scope.workedErrorOrder = function (row) {
            apiFactory.workedOnErrorsOrder(row.error.order_number, function () {
                row.DIDWO = true;
            });
        };
        //ALLIE TEST


        $timeout(function()
        {
            $scope.ajax_abort_contacts = apiFactory.getErroredContacts(function (data)
            {
                $scope.is_getting_contacts = false;

                $scope.contact_table_params.settings({
                    dataset: data
                });
            });
        }, 10);



        $timeout(function()
        {
            $scope.ajax_abort_orders = apiFactory.getErroredOrders(function (data)
            {
                $scope.is_getting_orders = false;

                $scope.order_table_params.settings({
                    dataset: data
                });
            });
        }, 10);
    }
]);