app.controller('leadsMissingTerritoryController', [
    '$scope', 'apiFactory', 'NgTableParams', '$filter',
    function ($scope, apiFactory, NgTableParams, $filter)
    {
        getLeads();

        $scope.workon = function (row) {
            apiFactory.workedOnLeadTerritory(row.id, function () {
                row.DIDWO = true;
            });
        };

        function getLeads()
        {
            $scope.is_getting_leads = true;

            apiFactory.getLeadsMissingTerritory(function (leads)
            {
                console.log(leads);
                $scope.is_getting_leads = false;

                $scope.leads_table = new NgTableParams({}, {
                    dataset: leads
                });
            });
        }
    }
]);
