app.factory('apiFactory', [
    '$http', '$location', '$q',
    function ($http, $location, $q)
    {
        return {
            isAuthenticated: function (callback)
            {
                return runGet('/api/is_authenticated', callback);
            },
            getUserInfo: function (callback)
            {
                return runGet('/api/user_info', callback);
            },
            getAvaliableUsers: function (callback)
            {
                return runGet('/api/all_users', callback);
            },
            addUser: function (user_data, callback)
            {
                return runPost('/api/add_user', user_data, callback);
            },
            editUser: function (user_data, callback)
            {
                return runPost('/api/edit_user', user_data, callback);
            },
            removeUser: function (user_id, callback)
            {
                return runPost('/api/remove_user', {user_id: user_id}, callback);
            },
            editRequest: function (request_data, callback)
            {
                return runPost('/api/edit_request', request_data, callback);
            },
            removeRequest: function (request_id, reason, callback)
            {
                return runPost('/api/remove_request', {request_id: request_id, voided_reason: reason}, callback);
            },
            addArrivalException: function (exception, callback)
            {
                return runPost('/api/add_arrival_exception', exception, callback);
            },
            addArrivalBlock: function (block, callback)
            {
                return runPost('/api/add_arrival_block', block, callback);
            },
            removeArrivalBlock: function (email, callback)
            {
                return runPost('/api/remove_arrival_block', {email: email}, callback);
            },
            getArrivalBlocks: function (callback)
            {
                return runGet('/api/get_arrival_blocks', callback);
            },
            addFinalizationBlock: function (block, callback)
            {
                return runPost('/api/add_finalization_block', block, callback);
            },
            removeFinalizationBlock: function (email, callback)
            {
                return runPost('/api/remove_finalization_block', {email: email}, callback);
            },
            getFinalizationBlocks: function (callback)
            {
                return runGet('/api/get_finalization_blocks', callback);
            },
            getSalesData: function (territory, year, type, fresh, callback)
            {
                return runGet('/api/sales_data/' + territory + '/' + year + '/' + type + (fresh ? '?fresh=1' : ''), callback);
            },
            futureOrdersOver3000: function (callback)
            {
                return runGet('/api/future_orders_over_3000', callback);
            },
            getMissingConfirmations: function (territory, callback)
            {
                return runGet('/api/missing_confirmations/' + territory, callback);
            },
            getArrivalsNotTracking: function (fresh, callback)
            {
                return runGet('/api/arrivals_not_tracking' + (fresh ? '?fresh=1' : ''), callback);
            },
            getNotFinalizedOrders: function (type, fresh, callback)
            {
                return runGet('/api/not_finalized_orders/' + type + (fresh ? '?fresh=1' : ''), callback);
            },
            getErroredCustomers: function (callback)
            {
                return runGet('/api/errored/customers', callback);
            },
            getErroredContacts: function (callback)
            {
                return runGet('/api/errored/contacts', callback);
            },
            getErroredOrders: function (callback)
            {
                return runGet('/api/errored/orders', callback);
            },
            getDuplicateOpportunities: function (callback)
            {
                return runGet('/api/errored/duplicate-opportunities', callback);
            },
            getLeadsMissingTerritory: function (callback)
            {
                return runGet('/api/errored/leads-missing-territory', callback);
            },
            getCorporateCustomers: function (date, callback)
            {
                return runGet('/api/corporate-customers/' + (!date ? moment().add(-2, 'years').format() : date), callback);
            },
            getUserSales: function (year, callback)
            {
                return runGet('/api/user_sales/' + year, callback);
            },
            getAllOrdersTimePeriod: function (start, end, callback)
            {
                return runGet('/api/orders/' + start + '/' + end, callback);
            },
            getAllOrderExceptionsTimePeriod: function (start, end, callback)
            {
                return runGet('/api/orders/territory-exceptions/' + start + '/' + end, callback);
            },
            saveTteException: function (exception, callback)
            {
                return runPost('/api/orders/territory-exceptions/save', {exception: exception}, callback);
            },
            removeTteException: function (id, callback)
            {
                return runPost('/api/orders/territory-exceptions/remove', {id: id}, callback);
            },
            getSwatchesNotShipped: function (callback)
            {
                return runGet('/api/orders/swatches-not-shipped', callback);
            },
            getAllConfirmationRequests: function (old, callback)
            {
                return runGet('/api/get-confirmation-requests' + (old ? '?include_old=true' : ''), callback);
            },
            getMissingConfirmationRequests: function (callback)
            {
                return runGet('/api/get-missing-confirmation-requests', callback);
            },
            getConfirmationRequest: function (request_id, callback)
            {
                return runGet('/api/get-confirmation-request/'+ request_id, callback);
            },
            getRequeststoVoid: function (order_number, callback)
            {
                return runPost('/api/get-requests-to-void', {order_number:order_number}, callback);
            },
            confirmRequest: function (request, callback)
            {
                return runPost('/api/confirm-request/', {request:request}, callback);
            },
            resendConfirmationRequestEmail: function (request_id, callback)
            {
                return runPost('/api/resend-request/', {request_id:request_id}, callback);
            },
            production: function (start_date, end_date, territory, callback)
            {
                var s = moment(start_date).format("YYYY-MM-DD");
                var e = moment(end_date).format("YYYY-MM-DD");

                return runGet('/api/production/'+s+'/'+e+'/'+territory, callback);
            },
            workedOnConfirmation: function (order_number, callback)
            {
                return runPost('/api/wo-missing-confirmations', {order_number: order_number}, callback);
            },
            workedOnArrivals: function (tracking_num, callback)
            {
                return runPost('/api/wo-arrivals', {tracking_num: tracking_num}, callback);
            },
            workedOnErrors: function (customer_num, callback)
            {
                return runPost('/api/wo-errored-customers', {customer_num: customer_num}, callback);
            },
            workedOnErrorsOrder: function (order_number, callback)
            {
                return runPost('/api/wo-errored-orders', {order_number: order_number}, callback);
            },
            workedOnQuotes: function (ORDNO, callback)
            {
                return runPost('/api/wo_quotes', {ORDNO: ORDNO}, callback);
            },
            workedOnDuplicateConf: function (order_number, callback)
            {
                return runPost('/api/wo_duplicate_conf', {order_number: order_number}, callback);
            },
            workedOnBadRequest: function (ORDNO, callback)
            {
                return runPost('/api/wo_bad_request', {ORDNO: ORDNO}, callback);
            },
            workedOnDuplicateEmails: function (CUSTNO, callback)
            {
                return runPost('/api/wo_duplicate_emails', {CUSTNO: CUSTNO}, callback);
            },
            workedOnAdditionalContactInfo: function (CUSTNAME, callback)
            {
                return runPost('/api/wo_additional_contact_info', {CUSTNAME: CUSTNAME}, callback);
            },
            workedOnPartyTrack: function (CUSTNO, callback)
            {
                return runPost('/api/wo_party_track', {CUSTNO: CUSTNO}, callback);
            },
            workedOnOrdersNoDate: function (ORDNO, callback)
            {
                return runPost('/api/wo_orders_no_date', {ORDNO: ORDNO}, callback);
            },
            workedOnSwatch: function (order_number, callback)
            {
                return runPost('/api/wo-swatch', {order_number: order_number}, callback);
            },
            addMissingConfirmationsExceptions: function (exception, callback)
            {
                return runPost('/api/add_missing_confirmations_exceptions', exception, callback);
            },
            workedOnFinalized: function (order_number, callback)
            {
                return runPost('/api/wo-finalized', {order_number: order_number}, callback);
            },
            workedOnLeadTerritory: function (lead_id, callback)
            {
                return runPost('/api/wo-lead-territory', {lead_id: lead_id}, callback);
            },
            getOrderswoAddress: function (callback)
            {
                return runGet('/api/orders_wo_address', callback);
            },
            getQuoteswoAddress: function (callback)
            {
                return runGet('/api/quotes_wo_address', callback);
            },
            getPersonalShouldBusiness: function (callback)
            {
                return runGet('/api/personal_should_business', callback);
            },
            getDuplicateEmails: function (callback)
            {
                return runGet('/api/duplicate_emails', callback);
            },
            getMissingConfirmationsExceptions: function (callback)
            {
                return runGet('/api/get_missing_confirmations_exceptions', callback);
            },
            getOrdersWODate: function (callback)
            {
                return runGet('/api/orders_wo_order_date', callback);
            },
            getDuplicateConfirmationRequests: function (callback)
            {
                return runGet('/api/duplicate_confirmation_requests', callback);
            },
            getBadRequestBy: function (callback)
            {
                return runGet('/api/bad_request_by', callback);
            },
            getAdditionalContactInfo: function (callback)
            {
                return runGet('/api/additional_contact_info', callback);
            },
            getProjectionsExport: function (callback, start, end)
            {
                var s = moment(start).format("YYYY-MM-DD");
                var e = moment(end).format("YYYY-MM-DD");
                return runGet('/api/get_projections/export/' + s + '/' + e, callback);
            },
            getDuplicateCustomers: function (callback)
            {
                return runGet('/api/errored/duplicate-customers', callback);
            },
            getTodaysNightlyProcess: function(callback) {
                return runGet("/master-process/today", callback);
            },
            getTodaysNightlyProcessLogs: function(callback) {
                return runGet("/master-process/todays-logs", callback);
            }
        };

        function runGet(url, callback)
        {
            var canceller = $q.defer();

            $http.get(url, {timeout: canceller.promise})
                .then(valid_callback(callback), invalid_callback(callback));

            return function (reason)
            {
                canceller.resolve(reason);
            };
        }

        function runPost(url, options, callback)
        {
            var canceller = $q.defer();

            $http.post(url, options, {timeout: canceller.promise})
                .then(valid_callback(callback), invalid_callback(callback));

            return function (reason)
            {
                canceller.resolve(reason);
            };
        }

        function valid_callback(callback)
        {
            return function (response)
            {
                if (response.data.valid)
                {
                    callback(response.data.data);
                }
                else
                {
                    if (response.data.error && response.data.error.code === 'not_authorized')
                    {
                        $location.path('/');
                    }
                    else if (response.data.error && response.data.error.hasOwnProperty('string'))
                    {
                        alert('There was an error: ' + response.data.error.string);
                    }
                    else
                    {
                        alert('System Error. Please try again. If problem persists, please contact support.');
                    }
                }
            }
        };

        function invalid_callback(callback)
        {
            return function (response)
            {
                console.error(response);
            }
        }
    }
]);
