app.controller('finalizationExceptionController', [
    '$scope', 'apiFactory', '$location',
    function ($scope, apiFactory)
    {
        $scope.block = {};
        $scope.blocks = [];

        function updateBlocks()
        {
            apiFactory.getFinalizationBlocks(function (blocks)
            {
                $scope.blocks = blocks;
            });
        }

        updateBlocks();

        $scope.save_block = function()
        {
            apiFactory.addFinalizationBlock($scope.block, function ()
            {
                alert('Block Added');
                $scope.block = {};
                updateBlocks();
            });
        }

        $scope.remove_block = function(email)
        {
            if (!confirm("Are you sure you wish to remove " + email + " from the block list?")) return;
            
            apiFactory.removeFinalizationBlock(email, function ()
            {
                alert('Block Removed');
                updateBlocks();
            })
        }
    }
]);