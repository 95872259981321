app.controller('swatchOrdersController', [
    '$scope', 'apiFactory', 'NgTableParams', '$filter',
    function ($scope, apiFactory, NgTableParams, $filter)
    {
        $scope.onlyNotWorkedOn = false;
        getSwatches();

        $scope.downloadCsv = function()
        {
            var stringIt = function (str)
            {
                if (!str) return '""';

                return '"' +
                str.replace(/^\s\s*/, '').replace(/\s*\s$/, '') // trim spaces
                    .replace(/"/g, '""') + // replace quotes with double quotes
                '"';
            };

            var sep = ","

            var dl = stringIt("Customer Name") + sep + stringIt("Last Order Date");

            $scope.all_customers.forEach(function (cust)
            {
                dl += "\n" +
                    stringIt(cust.CUSTNAME) + sep +
                    stringIt(cust.LASTORDERDATE ? $filter('mysqlDate')(cust.LASTORDERDATE) : 'Never');
            });


            var link = document.createElement('a');
            link.download = "CorporateCustomers.csv";
            link.href = 'data:text/csv;charset=UTF-8,' + encodeURIComponent(dl);
            link.click();
        };

        $scope.workon = function (row) {
            apiFactory.workedOnSwatch(row.ORDNO, function () {
                row.DIDWO = true;
            });
        };

        $scope.$watch('onlyNotWorkedOn', function () {
            if ($scope.onlyNotWorkedOn) {
                $scope.swatchsTable.settings({
                    dataset: $scope.all_data.filter(function (d) {
                        return !d.DIDWO;
                    })
                });
            } else {
                $scope.swatchsTable.settings({
                    dataset: $scope.all_data
                });
            }
        });

        function getSwatches()
        {
            $scope.is_getting = true;

            apiFactory.getSwatchesNotShipped(function (data)
            {
                $scope.is_getting = false;

                $scope.swatchsTable = new NgTableParams({}, {
                    dataset: data
                });

                $scope.all_data = data;
            });
        }
    }
]);
