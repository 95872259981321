app.controller('duplicateCustomersController', [
    '$scope','apiFactory', 'NgTableParams',
    function($scope, apiFactory, NgTableParams)
    {
        $scope.is_getting = true;

        $scope.getReportData = function() {
            var cb = function(data) {
                $scope.is_getting = false;
                $scope.duplicateCustomersTable = new NgTableParams({}, {
                    dataset: data
                });
                console.log(data);
            }
            apiFactory.getDuplicateCustomers(cb);
        }

        $scope.getReportData();
    }
]);