app.controller('arrivalExceptionController', [
    '$scope', 'apiFactory', '$location',
    function ($scope, apiFactory, $location)
    {
        $scope.exception = {};
        $scope.block = {};
        $scope.blocks = [];

        function updateBlocks()
        {
            apiFactory.getArrivalBlocks(function (blocks)
            {
                $scope.blocks = blocks;
            });
        }

        updateBlocks();



        $scope.save_exception = function()
        {
            apiFactory.addArrivalException($scope.exception, function()
            {
                alert('Exception Added');
                $scope.exception = {};
            });
        }

        $scope.save_block = function()
        {
            apiFactory.addArrivalBlock($scope.block, function ()
            {
                alert('Block Added');
                $scope.block = {};
                updateBlocks();
            });
        }

        $scope.remove_block = function(email)
        {
            if (!confirm("Are you sure you wish to remove " + email + " from the block list?")) return;
            
            apiFactory.removeArrivalBlock(email, function ()
            {
                alert('Block Removed');
                updateBlocks();
            })
        }
    }
]);