app.filter('telephone', function ()
{
    return function (phoneNumber)
    {
        if (!phoneNumber)
        {
            return phoneNumber;
        }

        return formatLocal('US', phoneNumber);
    }
});