window.app = angular.module('cclDb', [
    'ngRoute', 'ngTable', 'ngTableToCsv', 'highcharts-ng', 'ui.bootstrap', 'ngFileUpload'
]);

app.config([
    '$routeProvider',
    function ($routeProvider)
    {
        $routeProvider
            .when('/', {
                templateUrl: '/views/index',
                controller: 'indexController'
            })
            .when('/unauthorized', {
                templateUrl: '/views/unauthorized'
            })
            .when('/home', {
                templateUrl: '/views/home',
                controller: 'homeController'
            })
            .when('/customer-confirmation/request', {
                templateUrl: '/views/add_customer_confirmation_request',
                controller: 'customerConfirmationController'
            })
            .when('/customer-confirmation/requests', {
                templateUrl: '/views/customer_confirmation_requests',
                controller: 'customerConfirmationController'
            })
            .when('/customer-confirmation/missing-requests', {
                templateUrl: '/views/missing_customer_confirmation_requests',
                controller: 'customerConfirmationController'
            })
            .when('/customer-confirmation/response/:confirmation_request', {
                templateUrl: '/views/confirmation_response',
                controller: 'customerConfirmationController'
            })
            .when('/sales_report/:territory/:year/:fresh?', {
                templateUrl: '/views/sales_report',
                controller: 'salesReportController'
            })
            .when('/users', {
                templateUrl: '/views/users',
                controller: 'usersController'
            })
            .when('/add_arrival', {
                templateUrl: '/views/add_arrival',
                controller: 'arrivalExceptionController'
            })
            .when('/missing_confirmations_exception', {
                templateUrl: '/views/missing_confirmations_exception',
                controller: 'missingConfirmationsExceptionController'
            })
            .when('/missing_confirmations/:territory/', {
                templateUrl: '/views/missing_confirmations',
                controller: 'missingConfirmationsController'
            })
            .when('/arrivals_not_tracking', {
                templateUrl: '/views/arrivals_not_tracking',
                controller: 'arrivalsNotTrackingController'
            })
            .when('/orders_wo_address', {
                templateUrl: '/views/orders_wo_address',
                controller: 'ordersWOAddressController'
            })
            .when('/quotes_wo_address', {
                templateUrl: '/views/quotes_wo_address',
                controller: 'quotesWOAddressController'
            })
            .when('/duplicate_emails', {
                templateUrl: '/views/duplicate_emails',
                controller: 'duplicateEmailsController'
            })
            .when('/additional_contact_info', {
                templateUrl: '/views/additional_contact_info',
                controller: 'additionalContactInfoController'
            })
            .when('/personal_should_business', {
                templateUrl: '/views/personal_should_business',
                controller: 'personalShouldBusinessController'
            })
            .when('/orders_wo_order_date', {
                templateUrl: '/views/orders_wo_order_date',
                controller: 'ordersWOOrderDate'
            })
            .when('/duplicate_confirmation_requests', {
                templateUrl: '/views/duplicate_confirmation_requests',
                controller: 'duplicateConfirmationRequestsController'
            })
            .when('/bad_request_by', {
                templateUrl: '/views/bad_request_by',
                controller: 'badRequestByController'
            })
            .when('/not_finalized/:fresh?', {
                templateUrl: '/views/not_finalized',
                controller: 'notFinalizedOrdersController'
            })
            .when('/errored', {
                templateUrl: '/views/errored',
                controller: 'erroredController'
            })
            .when('/duplicate-opportunities', {
                templateUrl: '/views/duplicate_opportunities',
                controller: 'duplicateOpportunitiesController'
            })
            .when('/corporate-customers', {
                templateUrl: '/views/inactive_businesses',
                controller: 'corporateCustomersController'
            })
            .when('/add_finalization', {
                templateUrl: '/views/add_finalization',
                controller: 'finalizationExceptionController'
            })
            .when('/future-orders-over-2000', {
                templateUrl: '/views/future-orders-over-3000',
                controller: 'futureOrdersOver3000Controller'
            })
            .when('/territory-credit-exceptions', {
                templateUrl: '/views/territory-credit-exception',
                controller: 'territoryCreditExceptionController'
            })
            .when('/website-test-form', {
                templateUrl: '/views/test_form'
            })
            .when('/swatches-not-shipped', {
                templateUrl: '/views/swatch-orders',
                controller: 'swatchOrdersController'
            })
            .when('/production/:territory', {
                templateUrl: '/views/production',
                controller: 'productionController'
            })
            .when('/leads-missing-territory', {
                templateUrl: '/views/leads_missing_territory',
                controller: 'leadsMissingTerritoryController'
            })
            .when('/test-projections-export', {
                templateUrl: '/views/test-projections-export',
                controller: 'testProjectionsExportController'
            })
            .when('/duplicate-customers', {
                templateUrl: '/views/customer_duplicates',
                controller: 'duplicateCustomersController'
            })
            .when('/nightly_process_health', {
                templateUrl: '/views/nightly_process_health',
                controller: 'nightlyProcessHealthController'
            });
    }
]);

app.constant('sales_reports_hire_dates', {
    'CCL Atlanta': {year: 2016, 'month': 8},
    'CCL Baltimore': {year: 2016, 'month': 7},
    'CCL Denver': {year: 2016, 'month': 4},
    'CCL Indianapolis': {year: 2010, 'month': 11},
    'CCL Pittsburgh': {year: 2010, 'month': 7},
    'TTE': {year: 2015, 'month': 11}
})
