app.controller('duplicateEmailsController', [
    '$scope', 'apiFactory', '$routeParams', '$location', 'NgTableParams', '$timeout',
    function ($scope, apiFactory, $routeParams, $location, NgTableParams, $timeout)
    {
        getDuplicateEmails();

        $scope.workon = function (row) {
            apiFactory.workedOnDuplicateEmails(row.CUSTNO, function () {
                row.DIDWO = true;
            });
        };


        function getDuplicateEmails()
        {
            $scope.is_getting = true;

            apiFactory.getDuplicateEmails(function (data)
            {
                $scope.is_getting = false;

                $scope.emailsTable = new NgTableParams({}, {
                    dataset: data
                });

                $scope.all_data = data;
            });
        }
    }
]);