app.controller('nightlyProcessHealthController', [
    '$scope', 'apiFactory',
    function ($scope, apiFactory)
    {

        $scope.processLabels = {
            pt_mssql: "Pulling data from Party Track to MS SQL Server",
            pw_update_configurables: "Updating Custom Field Definitions from Copper",
            fedex_pull: "Pulling data from Fedex",
            mssql_mysqlext: "Converting Local MS SQL Server to Server MySQL Database",
            update_all_insert_companies: "Updating Companies from Copper in MySQL Database",
            update_all_insert_persons: "Updating People from Copper in MySQL Database",
            update_all_insert_leads: "Updating Leads from Copper in MySQL Database",
            update_all_insert_opportunities: "Updating Opportunities from Copper in MySQL Database",
            customer_convert: "Converting Customers Table",
            payments_convert: "Converting Payments Table",
            confirmation_sync: "Syncing Confirmations",
            finalization_auto_close: "Finalizing Customer Confirmation Requests",
            sales_cache: "Caching Sales Report Data",
            update_all: "Processing Companies, People, Leads & Opportunities",
            email_arrivals: "Sending Emails for Arrivals Not Tracking",
            email_finalization: "Sending Emails for Finalizations",
        };

        $scope.logs = {};

        $scope.process = null;
        $scope.loading = true;
        $scope.initialized = false;

        $scope.refreshRate = 15 * 1000;

        $scope.lastRefresh = null;
        $scope.nextRefresh = null;
        $scope.currentTime = new Date().toLocaleTimeString();

        $scope.makeLogHash = function(data) {
            $scope.logs = {};

            for (const row of data) {
                if (!$scope.logs[row.process]) {
                    $scope.logs[row.process] = [row];
                } else {
                    $scope.logs[row.process].push(row);
                }
            }
        }

        $scope.loadProcess = function(callback = false) {
            $scope.loading = true;
            apiFactory.getTodaysNightlyProcess(data => {
                $scope.process = data;

                apiFactory.getTodaysNightlyProcessLogs(data => {

                    $scope.makeLogHash(data);

                    console.log($scope.logs);

                    $scope.setLastRefresh();
                    $scope.loading = false;

                    if (!$scope.initialized) {
                        $scope.initialized = true;
                    }

                    if (callback) {
                        callback();
                    }
                });
            });
        };

        $scope.setLastRefresh = function() {
            let date = new Date();
            $scope.lastRefresh = date.toLocaleTimeString();
            $scope.nextRefresh = new Date(date.getTime() + $scope.refreshRate).toLocaleTimeString();
        }

        setInterval(function() {
            $scope.$apply(function() {
                $scope.currentTime = new Date().toLocaleTimeString();
            });
        }, 1000);

        $scope.autoRefresh = function() {
            setTimeout(_ => {
                $scope.loadProcess(() => {
                    $scope.autoRefresh();
                });
            }, $scope.refreshRate);
        }

        $scope.loadProcess(_ => {
            $scope.autoRefresh();
        });

        $scope.processLogs = function(process) {
            return $scope.logs[process] ? $scope.logs[process] : [];
        }
    }
]);
