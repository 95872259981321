app.controller('notFinalizedOrdersController', [
    '$scope', 'apiFactory', '$routeParams', '$location', 'NgTableParams', '$timeout',
    function ($scope, apiFactory, $routeParams, $location, NgTableParams, $timeout)
    {
        var fresh = $location.search().fresh || false;

        $scope.is_getting_ship = true;
        $scope.is_getting_local = true;

        $scope.table_ship = new NgTableParams({count: 10});
        $scope.table_local = new NgTableParams({count: 10});

        $scope.ship_cancel = null;
        $scope.local_cancel = null;

        $scope.$on('$destroy', function()
        {
            if ($scope.ship_cancel) $scope.ship_cancel();
            if ($scope.local_cancel) $scope.local_cancel();
        });

        $scope.workon = function (row) {
            apiFactory.workedOnFinalized(row.ORDNO, function () {
                row.DIDWO = true;
            });
        };

        $scope.$watch('onlyNotWorkedOn', function () {
            if ($scope.onlyNotWorkedOn) {
                $scope.table_ship.settings({
                    dataset: $scope.shipData.filter(function (d) {
                        return !d.DIDWO;
                    })
                });
                $scope.table_local.settings({
                    dataset: $scope.localData.filter(function (d) {
                        return !d.DIDWO;
                    })
                });
            } else {
                $scope.table_ship.settings({
                    dataset: $scope.shipData
                });
                $scope.table_local.settings({
                    dataset: $scope.localData
                });
            }
        });

        $timeout(function(){
            $scope.ship_cancel = apiFactory.getNotFinalizedOrders('ship', fresh, function (data)
            {
                $scope.is_getting_ship = false;
                $scope.shipData = data;

                $scope.table_ship.settings({
                    dataset: data
                });
            });

            $scope.local_cancel = apiFactory.getNotFinalizedOrders('local', fresh, function (data)
            {
                $scope.is_getting_local = false;
                $scope.localData = data;

                $scope.table_local.settings({
                    dataset: data
                });
            });
        }, 10);
    }
]);
